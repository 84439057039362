:root {
  --primary-color: #ff9900;
  --secondary-color: #630001;
  --hotspotText: "Hotspot";
  --hotspotTextTwo: "Hotspot";
  --hotspotTextThree: "Hotspot";
  --hotspotTextFour: "Hotspot";
  --hotspotTextFive: "Hotspot";
  --hotspotTextSix: "Hotspot";
  --hotspotTextSeven: "Hotspot";
  --hotspotTextEight: "Hotspot";
  --hotspotTextNine: "Hotspot";
  --hotspotTextTen: "Hotspot";
  --hotspotTextEleven: "Hotspot";
  --hotspotTextTwelve: "Hotspot";
  --hotspotTextThirteen: "Hotspot";
  --hotspotTextFourteen: "Hotspot";
  --hotspotTextFifteen: "Hotspot";
  --hotspotTextSixteen: "Hotspot";
  --hotspotTextSeventeen: "Hotspot";
  --hotspotTextEighteen: "Hotspot";
  --hotspotTextNineteen: "Hotspot";
  --hotspotTextTwenty: "Hotspot";
  --hotspotTextTwentyOne: "Hotspot";
  --hotspotTextTwentyTwo: "Hotspot";
  --hotspotTextTwentyThree: "Hotspot";
  --hotspotTextTwentyFour: "Hotspot";
  --hotspotTextTwentyFive: "Hotspot";
  --hotspotTextTwentySix: "Hotspot";
  --hotspotLeft: -44px;
  --hotspotLeftTwo: -44px;
  --hotspotLeftThree: -44px;
  --hotspotLeftFour: -44px;
  --hotspotLeftFive: -44px;
  --hotspotLeftSix: -44px;
  --hotspotLeftSeven: -44px;
  --hotspotLeftEight: -44px;
  --hotspotLeftNine: -44px;
  --hotspotLeftTen: -44px;
  --hotspotLeftEleven: -44px;
  --hotspotLeftTwelve: -44px;
  --hotspotLeftThirteen: -44px;
  --hotspotLeftFourteen: -44px;
  --hotspotLeftFifteen: -44px;
  --hotspotLeftSixteen: -44px;
  --hotspotLeftSeventeen: -44px;
  --hotspotLeftEighteen: -44px;
  --hotspotLeftNineteen: -44px;
  --hotspotLeftTwenty: -44px;
  --hotspotLeftTwentyOne: -44px;
  --hotspotLeftTwentyTwo: -44px;
  --hotspotLeftTwentyThree: -44px;
  --hotspotLeftTwentyFour: -44px;
  --hotspotLeftTwentyFive: -44px;
  --hotspotLeftTwentySix: -44px;
  --vh: 1vh;
}

html {
  padding: env(safe-area-inset);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #e0e0e012;
  text-align: center;
  height: 100%;
  overflow-x: hidden;
}

#demo {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app {
  margin: 0;
  padding: 0;
  height: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.page-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: rgba(52, 52, 52, 255);
}

.video-page-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.landing-bg {
  background: url(images/landing-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.landing-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  background: #00000099;
}

.center-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-center-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(151 151 151 / 36%);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 36px 24px;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sl-emblem {
  margin-bottom: 20px;
  height: 78px;
  border-radius: 14px;
}

.landing-heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
}

.language-select-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 12px;
}

.language-btn {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: #1e1e1e !important;
  width: 100%;
  margin-bottom: 12px !important;
  background: linear-gradient(
    94.25deg,
    var(--primary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 15px 144px !important;
}

.language-btn:hover {
  background: linear-gradient(
    93.29deg,
    var(--secondary-color) 2.14%,
    #000000 278.22%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #f8f8f8 !important;
}

.background-video-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  margin-top: 8px;
  cursor: pointer;
}

.background-video-link:hover {
  color: var(--primary-color);
}

.start-btn-container {
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.start-btn-container:hover,
.start-btn-container:active {
  opacity: 1;
  /* visibility: visible; */
}

.start-tour-btn {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: #1e1e1e !important;
  width: 100%;
  margin-bottom: 12px !important;
  background: linear-gradient(
    94.25deg,
    var(--primary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 15px 40px !important;
  position: absolute !important;
  width: fit-content;
  bottom: 10px;
  right: 25px;
}

.hide-start-btn-container {
  opacity: 0.4;
  /* visibility: hidden; */
}

.start-tour-btn:hover {
  background: linear-gradient(
    93.29deg,
    var(--secondary-color) 2.14%,
    #000000 278.22%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #f8f8f8 !important;
}

.video-bg {
  display: flex;
  height: fit-content;
  background: black;
}

.video-container {
  width: 100% !important;
  height: 100% !important;
}

.video-container-video {
  width: 100% !important;
  height: 100% !important;
}

.tutorial-bg {
  background: url(images/tutorial-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.tutorial-backdrop {
  background: #00000080;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorial-slide-img {
  width: 100%;
  /* height: 266px; */
  border-radius: 8px;
  margin-bottom: 24px;
}

.tutorial-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  height: 70px;
}

.tutorial-modal-container {
  position: absolute;
  background: rgb(0 0 0 / 16%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.tutorial-dialog-container {
  background: rgba(151, 151, 151, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  width: 30%;
}

.tutorial-popup-container {
  background: rgba(151, 151, 151, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  width: 400px;
}

.tutorial-popup {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.tutorial-next-btn {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: #1e1e1e !important;
  width: 100%;
  margin-bottom: 12px !important;
  background: linear-gradient(
    94.25deg,
    var(--primary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 15px 40px !important;
  margin-bottom: 24px;
}

.tutorial-next-btn:hover {
  background: linear-gradient(
    93.29deg,
    var(--secondary-color) 2.14%,
    #000000 278.22%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #f8f8f8 !important;
}

.skip-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  cursor: pointer;
}

.skip-link:hover {
  color: var(--primary-color);
}

.ellipse-group {
  display: flex;
  align-items: center;
  margin: 24px 0px;
}

.ellipse-circle-active {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--primary-color);
  margin-right: 20px;
  cursor: pointer;
}

.ellipse-circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ababab;
  margin-right: 20px;
  cursor: pointer;
}

.ellipse-circle-final {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ababab;
  cursor: pointer;
}

.ellipse-circle-active-final {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;
}

.footer-section {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  z-index: 2;
}

.footer-container {
  display: grid;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 14px 12px;
  grid-template-columns: 1fr 2fr 1fr;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.show-footer {
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  visibility: visible;
  opacity: 1;
}

.hide-footer {
  -webkit-animation: fade-out 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: hidden;
  opacity: 0;
}

.footer-fade-out {
  -webkit-animation: fade-out 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: hidden;
  opacity: 0;
}

.footer-fade-in {
  -webkit-animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  visibility: visible;
  opacity: 1;
}

.show-none {
  -webkit-animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: hidden;
  opacity: 0;
}

.show-in {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  visibility: visible;
  opacity: 1;
}

.footer-container-mob {
  display: none;
}

.locations-btn {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: var(--primary-color) !important;
  width: fit-content;
  padding: 14px 32px !important;
  border: 1px solid var(--primary-color) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  margin-right: auto !important;
}

.locations-btn:hover {
  background: linear-gradient(
    94.25deg,
    var(--primary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #000000 !important;
}

.stepper-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.footer-icon-container {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}

.footer-icon-section {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}

.footer-icon {
  width: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.home-icon {
  margin-right: 20px;
  background: url("images/icons/home.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

.home-icon:hover {
  filter: none;
}

.tutorial-icon {
  margin-right: 20px;
  background: url("images/icons/tutorial.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

.tutorial-icon:hover {
  filter: none;
}

.full-screen-icon {
  margin-right: 20px;
  background: url("images/icons/full_screen.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  filter: brightness(0) invert(1);
}

.full-screen-icon:hover {
  filter: none;
}

.exit-full-screen > .footer-icon-container > .full-screen-icon {
  background: url("images/icons/exit_full_screen.svg") !important;
  filter: brightness(0) invert(1);
}

.exit-full-screen > .footer-icon-container > .full-screen-icon:hover {
  filter: none;
}

.stepper-location-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

.stepper-location-name-active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 15px #ac6700;
}

.stepper-nav-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 15px;
}

.prev-next-icon {
  color: var(--primary-color) !important;
}

.stepper-locations {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}

.stepper-navigations {
  display: flex;
  align-items: center;
  margin: 16px 32px;
}

.stepper-navigations-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: var(--primary-color);
  margin-top: -2px;
  cursor: pointer;
  opacity: 0.6;
}

.mlp-2 {
  margin-left: 2px;
}

.mlpm-10 {
  margin-left: -10px;
}

.mlpm-54 {
  margin-left: 54px;
}

.ml-70 {
  margin-left: 70px;
}

.mlm-30 {
  margin-left: -30px;
}

.mlp-52 {
  margin-left: 52px;
}

.mlp-86 {
  margin-left: 86px;
}

.mlp-40 {
  margin-left: 40px;
}

.mlp-60 {
  margin-left: 60px !important;
}

.mlp-26 {
  margin-left: 26px !important;
}

.mlm-86 {
  margin-left: -86px;
}

.mlm-78 {
  margin-left: -78px !important;
}

.mlp-22 {
  margin-left: 22px;
}

.mlp-2 {
  margin-left: 2px;
}

.mlpm-60 {
  margin-left: -60px;
}

.mlpm-68 {
  margin-left: -68px;
}

.mlp-8 {
  margin-left: 8px;
}

.mlc-72 {
  margin-left: 72px;
}

.mlc-96 {
  margin-left: 96px;
}

.mlc-60 {
  margin-left: 60px;
}

.mlc-50 {
  margin-left: 50px;
}

.mlp-104 {
  margin-left: 104px;
}

.mlp-72 {
  margin-left: 72px;
}

.mlp-42 {
  margin-left: 42px;
}

.w-160 {
  width: 160px;
}

.mlm-4 {
  margin-left: -4px;
}

.mlpm-30 {
  margin-left: -30px;
}

.mlpm-0 {
  margin-left: 0px;
}

.mlp-8 {
  margin-left: 8px !important;
}

.mlp-44 {
  margin-left: 44px;
}

.mlm-44 {
  margin-left: -44px;
}

.mlp-20 {
  margin-left: 20px;
}

.mlm-82 {
  margin-left: -82px;
}

.stepper-line-right {
  width: 100%;
  height: 2px;
  border: 1px solid var(--primary-color);
  margin: 0 14px 0 14px;
  opacity: 0.6;
}

.stepper-line-left {
  width: 100%;
  height: 2px;
  border: 1px solid var(--primary-color);
  margin: 0 14px 0 14px;
  opacity: 0.6;
}

.mr-10 {
  margin-right: 10px;
}

.stepper-circle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    94.25deg,
    color-mix(in srgb, var(--primary-color), transparent 56%) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 4px 8px;
  border-radius: 20px;
}

.stepper-circle-multi-inner {
  background-color: white;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-circle-multi {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active-stepper {
  border: 1px solid white;
}

.pulse {
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
  animation: pulsate-fwd 1s ease-in-out infinite both;
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.stepper-circle-outer {
  border: 2px solid var(--primary-color);
  padding: 2px;
  border-radius: 50%;
  width: 44px;
}

.stepper-circle-inner {
  background: var(--primary-color);
  border-radius: 50%;
  height: 15px;
}

.locations-dialog > .MuiDialog-container > .MuiPaper-root {
  background: rgb(0 0 0 / 37%) !important;
  backdrop-filter: blur(10px) !important;
}

.close-icon {
  margin-left: auto !important;
}

.close-icon > svg {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
}

.close-full-icon {
  position: absolute !important;
  top: 0px;
  right: 0px;
}

.close-full-icon > svg {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
}

.dialog-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.location-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 37px;
  grid-row-gap: 24px;
}

.location-card {
  display: flex;
  flex-direction: column;
  padding: 8px;
  cursor: pointer;
}

.location-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location-detail-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
}

.location-detail-name-active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: var(--primary-color);
}

.location-tick {
  width: 20px !important;
  height: 20px !important;
  color: var(--primary-color) !important;
}

.location-dialog-img {
  border-radius: 5px;
  width: 100%;
  height: 190px;
}

.dialog-header-row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.locations-btn-link {
  display: none;
}

.mob-menu-btn {
  display: none !important;
}

.full-dialog-image {
  width: auto;
  height: auto;
  max-width: 97vw;
  max-height: 85vh;
  border-radius: 14px;
}

.full-dialog {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.full-info-dialog {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  z-index: 5;
  width: 100vw;
  height: 100vh;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* visibility: hidden;
  opacity: 0; */
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.unclickable {
  cursor: default !important;
}

.full-image-dialog {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  z-index: 3;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 97vw;
  height: 94vh;
  margin: 24px 24px;
  /* visibility: hidden;
  opacity: 0; */
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.full-image-container {
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.modal-bg {
  position: absolute;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 90vh;
  width: 100vw;
  z-index: 3;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.all-locations-popup-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 4;
  padding: 40px;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-y: auto;
}

.all-locations-close-icon {
  position: absolute !important;
  right: 0;
  top: 0;
}

.all-locations-close-icon > svg {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
  margin-right: 5px !important;
  margin-top: 5px !important;
}

.all-locations-dialog {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* visibility: hidden;
  opacity: 0; */
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.info-dialog-popup-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 4;
  padding: 40px;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.info-dialog {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* visibility: hidden;
  opacity: 0; */
  transition: visibility 0.5s linear, opacity 0.5s linear;
  height: auto;
  max-height: 750px;
  overflow-y: auto;
  -webkit-overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  z-index: 3;
}

.dialog-open {
  /* -webkit-animation: slide-in-elliptic-right-fwd 0.7s ease-in-out both;
  animation: slide-in-elliptic-right-fwd 0.7s ease-in-out both; */
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* -webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; */
  opacity: 1;
  visibility: visible;
}

.dialog-close {
  -webkit-animation: fade-out 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-out 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* -webkit-animation: slide-out-elliptic-right-bck 0.7s ease-in-out both;
  animation: slide-out-elliptic-right-bck 0.7s ease-in-out both; */
  /* -webkit-animation: flip-out-ver-right 0.5s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: flip-out-ver-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; */
  opacity: 0;
  visibility: hidden;
}

.presentation-popup-container {
  background: rgb(0 0 0 / 16%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.presentation-popup {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  z-index: 4;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.presentation-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.presentation-popup-text {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
}

.presentation-view-btn-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.presentation-btn-yes {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: #1e1e1e !important;
  width: 100%;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  background: linear-gradient(
    94.25deg,
    var(--primary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 15px 144px !important;
}

.presentation-btn-yes:hover {
  background: linear-gradient(
    93.29deg,
    var(--secondary-color) 2.14%,
    #000000 278.22%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #f8f8f8 !important;
}

.presentation-btn-no {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center;
  color: #ffffff !important;
  width: 100%;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  background: linear-gradient(
    94.25deg,
    var(--secondary-color) 3.11%,
    #ffffff 372.56%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  padding: 15px 144px !important;
}

.presentation-btn-no:hover {
  background: linear-gradient(
    93.29deg,
    var(--primary-color) 2.14%,
    #000000 278.22%
  ) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  color: #f8f8f8 !important;
}

.mb-2 {
  margin-bottom: 10px;
}

.tutorial-content-desc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@-webkit-keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
    transform: rotateY(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
    transform: rotateY(70deg);
    opacity: 0;
  }
}

@-webkit-keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

.full-info-dialog-img {
  width: 100%;
  height: 100%;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* -webkit-animation: slit-in-vertical 0.45s ease-out both;
  animation: slit-in-vertical 0.45s ease-out both; */
}

.info-dialog-img {
  border-radius: 14px;
  width: 362px;
  margin-bottom: 17px;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  /* -webkit-animation: slit-in-vertical 0.45s ease-out both;
  animation: slit-in-vertical 0.45s ease-out both; */
}

.info-dialog-img-sm {
  width: 362px;
  cursor: pointer;
}

.img-skeleton-sm {
  border-radius: 10px;
  width: 362px;
  height: 242px;
  margin-bottom: 17px;
  background: #606060;
}

.info-dialog-img-full {
  width: 94vw;
  object-fit: contain;
  height: 90vh;
  margin-bottom: 0px;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}

.info-close-icon {
  position: absolute !important;
  right: 20px;
  top: 20px;
  z-index: 5;
}

.info-close-icon > svg {
  width: 32px !important;
  height: 32px !important;
  color: white !important;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-view-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  width: auto;
  max-width: 300px;
  text-align: left;
}

.audio-icon-container {
  width: 36px;
  height: 36px;
  margin-left: 12px;
  cursor: pointer;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 4px 2px;
}

.audio-pause-img {
  background: url("images/pause.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(0.7);
}

.audio-img {
  background: url("images/audio.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
}

.audio-icon-container:hover {
  background: var(--secondary-color);
}

.audio-icon-container:hover > .audio-img {
  background: url("images/audio-hover.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transform: scale(0.8);
}

.info-desc-container {
  margin-top: 17px;
  overflow: auto;
  -webkit-overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 365px;
  display: flex;
}

.info-desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: justify;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 98%;
  height: auto;
  max-height: 124px;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  /* padding-right: 14px; */
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.info-desc-expand {
  display: block;
  height: auto;
  /* max-height: 244px; */
  max-height: 100%;
  /* overflow-y: auto; */
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

.see-more-less-container {
  display: flex;
  justify-content: flex-end;
  color: white;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.hotspot-circle-container {
  position: absolute;
  top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hotspot-circle-out {
  background-color: var(--primary-color) !important;
  width: 18px !important;
  height: 18px !important;
  border: 4px solid white;
  border-radius: 50%;
}

.hotspot-circle-in {
  background-color: var(--primary-color) !important;
  width: 36px !important;
  height: 36px !important;
  border: 5px solid #ffae36 !important;
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
  border-radius: 50%;
  position: relative;
  top: -13px;
  left: -13px;
}

.hotspot-label {
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  font-weight: 500;
  margin-top: 10px;
}

.pnlm-hotspot-base > .hotspot > .in {
  background-color: var(--primary-color) !important;
  width: 10px !important;
  height: 10px !important;
  top: 10px !important;
  left: 10px !important;
  border: 4px solid white;
}

.pnlm-hotspot-base > .hotspot > .out {
  background-color: var(--primary-color) !important;
  width: 36px !important;
  height: 36px !important;
  border: 5px solid #ffae36 !important;
  -webkit-animation: ping 0.8s ease-in-out infinite both;
  animation: ping 0.8s ease-in-out infinite both;
}

.pnlm-grab {
  background: linear-gradient(
    180deg,
    rgb(255 255 255 / 0%) 0%,
    rgba(255, 255, 255, 0) 70%,
    rgb(26 26 26) 100%
  );
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.pnlm-container {
  background: rgba(52, 52, 52, 255) !important;
}

.pnlm-load-box {
  background-color: transparent !important;
}

.pnlm-lbar {
  height: 12px !important;
  border-radius: 4px !important;
}

.pnlm-lbar-fill {
  border-radius: 4px !important;
}

.pnlm-lbox {
  display: none !important;
}

.pnlm-load-box p {
  color: transparent !important;
  margin: 0px 0px !important;
}

.pnlm-load-box p::before {
  content: "LOADING" !important;
  color: rgba(194, 145, 66, 255) !important;
  position: absolute;
  bottom: 62px;
  font-family: "Inter";
  font-style: normal;
  letter-spacing: 5px;
  left: 40px;
}

.pnlm-load-box > .pnlm-lmsg {
  display: none !important;
}

.loc-hotspot:hover::after {
  content: var(--hotspotText);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeft);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-two:hover::after {
  content: var(--hotspotTextTwo);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwo);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-three:hover::after {
  content: var(--hotspotTextThree);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftThree);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-four:hover::after {
  content: var(--hotspotTextFour);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftFour);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-five:hover::after {
  content: var(--hotspotTextFive);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftFive);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-six:hover::after {
  content: var(--hotspotTextSix);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftSix);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-seven:hover::after {
  content: var(--hotspotTextSeven);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftSeven);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-eight:hover::after {
  content: var(--hotspotTextEight);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftEight);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-nine:hover::after {
  content: var(--hotspotTextNine);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftNine);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-ten:hover::after {
  content: var(--hotspotTextTen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-eleven:hover::after {
  content: var(--hotspotTextEleven);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftEleven);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twelve:hover::after {
  content: var(--hotspotTextTwelve);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwelve);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-thirteen:hover::after {
  content: var(--hotspotTextThirteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftThirteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-fourteen:hover::after {
  content: var(--hotspotTextFourteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftFourteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-fifteen:hover::after {
  content: var(--hotspotTextFifteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftFifteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-sixteen:hover::after {
  content: var(--hotspotTextSixteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftSixteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-seventeen:hover::after {
  content: var(--hotspotTextSeventeen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftSeventeen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-eighteen:hover::after {
  content: var(--hotspotTextEighteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftEighteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-nineteen:hover::after {
  content: var(--hotspotTextNineteen);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftNineteen);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twenty:hover::after {
  content: var(--hotspotTextTwenty);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwenty);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentyone:hover::after {
  content: var(--hotspotTextTwentyOne);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentyOne);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentytwo:hover::after {
  content: var(--hotspotTextTwentyTwo);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentyTwo);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentythree:hover::after {
  content: var(--hotspotTextTwentyThree);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentyThree);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentyfour:hover::after {
  content: var(--hotspotTextTwentyFour);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentyFour);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentyfive:hover::after {
  content: var(--hotspotTextTwentyFive);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentyFive);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-twentysix:hover::after {
  content: var(--hotspotTextTwentySix);
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: var(--hotspotLeftTwentySix);
  top: 6px;
  font-weight: 500;
}

.loc-hotspot-full:hover::after {
  content: "Full Screen Hotspot";
  color: #000000;
  background: var(--primary-color);
  padding: 4px 12px;
  font-family: "Inter";
  font-style: normal;
  border-radius: 5px;
  position: relative;
  left: -28px;
  top: 6px;
  font-weight: 500;
}

.row-two-p-hidden {
  visibility: hidden !important;
  opacity: 1;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-two-p-visible {
  visibility: visible !important;
  opacity: 0;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-three-p-hidden {
  visibility: hidden !important;
  opacity: 1;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-three-p-visible {
  visibility: visible !important;
  opacity: 0;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-four-p-hidden {
  visibility: hidden !important;
  opacity: 1;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-four-p-visible {
  visibility: visible !important;
  opacity: 0;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-five-p-hidden {
  visibility: hidden !important;
  opacity: 1;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.row-five-p-visible {
  visibility: visible !important;
  opacity: 0;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.menu-container > .MuiPaper-root {
  background: #282828 !important;
}

.audio-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-div {
  width: 300px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  transition: ease 0.2s;
  position: relative;
}

.audio-wave {
  height: 20px;
  width: 4px;
  border-radius: 10px;
  background: #f6a300;
  margin: 0 3px;
  padding: 0;
  animation-name: wave4;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: ease 0.2s;
}

.audio-wave:nth-child(2) {
  animation-name: wave2;
  animation-delay: 0.2s;
}
.audio-wave:nth-child(3) {
  animation-name: wave3;
  animation-delay: 0.23s;
  animation-duration: 0.4s;
}
.audio-wave:nth-child(4) {
  animation-name: wave4;
  animation-delay: 0.1s;
  animation-duration: 0.3s;
}
.audio-wave:nth-child(5) {
  animation-delay: 0.5s;
}
.audio-wave:nth-child(6) {
  animation-name: wave2;
  animation-duration: 0.5s;
}
.audio-wave:nth-child(8) {
  animation-name: wave4;
  animation-delay: 0.4s;
  animation-duration: 0.25s;
}
.audio-wave:nth-child(9) {
  animation-name: wave3;
  animation-delay: 0.15s;
}
.audio-wave:nth-child(10) {
  animation-delay: 0.5s;
}
.audio-wave:nth-child(11) {
  animation-name: wave2;
  animation-duration: 0.5s;
}
.audio-wave:nth-child(12) {
  animation-name: wave3;
  animation-delay: 0.4s;
  animation-duration: 0.25s;
}
.audio-wave:nth-child(13) {
  animation-name: wave4;
  animation-delay: 0.15s;
}
.audio-wave:nth-child(14) {
  animation-name: wave4;

  animation-duration: 0.5s;
}
.audio-wave:nth-child(15) {
  animation-name: wave4;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
}

.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: rgba(52, 52, 52, 255);
  color: white;
  font-family: "Inter";
  font-style: normal;
}

.not-found-heading {
  font-size: 54px;
  font-weight: 500;
}

.not-found-sub-heading {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 75px;
}

.not-found-desc {
  font-size: 24px;
  margin-bottom: 10px;
}

.not-found-link-text {
  text-decoration: underline;
  cursor: pointer;
}

.video-containe {
  width: 100vw !important;
  height: 100% !important;
}

.loading-container {
  background: #323232;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-text {
  color: rgba(194, 145, 66, 255) !important;
  font-family: "Inter";
  font-style: normal;
  letter-spacing: 16px;
  font-size: 24px;
  margin-left: 20px;
}

.loader {
  width: 234px;
  margin: 0 auto;
  border-radius: 7px;
  border: 4px solid transparent;
  position: relative;
  padding: 8px;
  margin-bottom: 14px;
}
.loader:before {
  content: "";
  border: 1px solid #fff;
  border-radius: 7px;
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}
.loader .loaderBar {
  position: absolute;
  border-radius: 7px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

.loading-black-bg {
  position: absolute;
  z-index: 10;
  background: black;
  width: 100vw;
  height: 100vh;
}

.mlpme {
  margin-left: 62px;
}

.mlpme-66 {
  margin-left: 66px;
}

.mlpme-24 {
  margin-left: 24px;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

@keyframes wave1 {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0.5);
  }
}
@keyframes wave2 {
  from {
    transform: scaleY(0.3);
  }
  to {
    transform: scaleY(0.6);
  }
}
@keyframes wave3 {
  from {
    transform: scaleY(0.6);
  }
  to {
    transform: scaleY(0.8);
  }
}
@keyframes wave4 {
  from {
    transform: scaleY(0.2);
  }
  to {
    transform: scaleY(0.5);
  }
}

@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
    transform: scale(2.2);
    opacity: 0;
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

.img-skeleton {
  width: 362px !important;
  height: 242px !important;
  z-index: 2 !important;
  position: absolute;
}

.skeleton-container {
  width: 362px !important;
  height: 264px !important;
}

.info-content-row {
  display: flex;
  flex-direction: column;
}

.tutorial-content-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hide-audio {
  visibility: hidden;
  height: 0px;
}

@-webkit-keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
    transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
    transform-origin: -1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
    transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
    transform-origin: -1800px 50%;
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
    transform-origin: -1800px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(-30deg) scale(0);
    transform: translateX(1000px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 1;
  }
}
@keyframes slide-out-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
    transform-origin: -1800px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(-30deg) scale(0);
    transform: translateX(1000px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 1;
  }
}

@media (min-width: 320px) and (max-width: 480px) {

  .tutorial-audio-div {
    width: 140px !important;
  }

  .mob-center-align {
    align-items: center;
  }

  .info-view-text {
    max-width: 250px;
  }

  .location-dialog-img {
    height: auto !important;
  }

  .audio-img {
    transform: scale(0.6);
  }

  .audio-pause-img {
    transform: scale(0.5);
  }

  .presentation-popup-container {
    display: none;
  }

  .presentation-btn-yes {
    padding: 15px 15px !important;
    width: 50%;
  }

  .presentation-btn-no {
    padding: 15px 15px !important;
    width: 50%;
  }

  .full-dialog-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .full-dialog-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .skeleton-container {
    width: 100% !important;
  }

  .info-dialog-popup-container {
    justify-content: center;
  }

  .info-dialog {
    width: 100%;
    max-height: 580px;
  }

  .img-skeleton {
    width: 290px !important;
  }

  .img-skeleton-no-audio {
    width: 290px !important;
  }

  .info-dialog-img {
    width: 100%;
  }

  .info-dialog-img-sm {
    width: 100%;
    object-fit: contain;
    height: 280px;
  }

  .center-container {
    margin: 0 auto;
    justify-content: space-evenly;
  }

  .landing-center-container {
    margin: unset;
    background: none;
    border: none;
    backdrop-filter: none;
    border-radius: 0;
    padding: 46px 0px 0px 0px;
    justify-content: space-between;
    height: 100%;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .background-video-link {
    margin-top: 12px;
    margin-bottom: 100px;
  }

  .tutorial-bg {
    background-position: center;
  }

  .tutorial-dialog-container {
    width: 75%;
  }

  .tutorial-popup-container {
    width: 250px;
  }

  .tutorial-popup {
    top: 5%;
    transform: translateY(-5%);
  }

  .ellipse-group {
    margin: 58px 0px 24px 0px;
  }

  .sl-emblem {
    height: 72px;
  }

  .landing-heading {
    font-size: 18px;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    width: 100vw;
    padding: 12px 64px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }

  .language-div {
  }

  .language-btn {
    width: 85%;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 12px;
    height: 12px;
  }

  .hotspot-circle-container {
    top: 72px;
  }

  .full-info-dialog {
    padding: 24px;
  }

  .close-full-icon {
    top: 0px;
    right: 0px;
  }

  .full-info-dialog-img {
    object-fit: fill;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 950px) and (orientation: landscape) and (orientation: landscape) {
  .tutorial-content-desc-container {
    align-items: flex-start;
  }

  .tutorial-audio-container {
    margin-left: 20px;
  }

  .tutorial-audio-div {
    width: 200px !important;
    height: 0px !important;
  }

  .presentation-popup-container {
    display: none;
  }

  .center-container {
    margin: 10px auto !important;
  }

  .landing-center-container {
    padding: 10px 24px 0px 24px !important;
    margin: 10px 0px 0px 0px !important;
  }

  .video-container {
    height: 100% !important;
  }

  .video-container-video {
    height: 100% !important;
  }

  .sl-emblem {
    margin-bottom: 10px !important;
    height: 50px !important;
  }

  .landing-heading {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .language-select-text {
    margin-top: 22px !important;
    margin-bottom: 12px !important;
  }

  .language-btn {
    padding: 9px 94px !important;
  }

  .background-video-link {
    margin-top: 14px !important;
    margin-bottom: 0px !important;
  }

  .tutorial-popup {
    padding: 12px;
    width: 550px;
  }

  .tutorial-popup-container {
    width: 100%;
    padding: 12px !important;
    height: auto;
    max-height: 250px;
  }

  .tutorial-content-row {
    flex-direction: row;
  }

  .tutorial-dialog-container {
    width: 30% !important;
    /* padding: 10px 20px 0px 20px; */
  }

  .tutorial-desc {
    height: 100% !important;
    font-size: 14px !important;
    text-align: left;
    margin-left: 20px;
  }

  .tutorial-next-btn {
    width: 50%;
    font-size: 14px !important;
    line-height: 6px !important;
    margin-bottom: 0px !important;
  }

  .skip-link {
    margin-top: 10px;
  }

  .ellipse-group {
    margin: 16px 0 20px 0 !important;
  }

  /* html { position:fixed !important; width:100% !important;  height:100% !important; overflow:hidden !important; } */

  /* .full-info-dialog {
    padding: 0px !important;
  }

  .info-dialog-popup-container {
    top: -40px !important;
  } */

  .tutorial-modal-container {
    top: -40px !important;
  }

  .tutorial-slide-img {
    width: 30% !important;
    margin-bottom: 0px !important;
  }

  .footer-container {
    display: none !important;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed !important;
    bottom: 10px !important;
    width: 100vw;
    padding: 12px 240px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .info-dialog-img-sm {
    width: auto;
    max-width: 200px;
    height: 180px;
    object-fit: contain;
  }

  .info-dialog {
    max-height: 270px;
  }

  .info-content-row-margin {
    margin-left: 24px;
  }

  .info-dialog-long {
    flex-direction: row !important;
  }

  .skeleton-container {
    width: 260px !important;
    height: 174px !important;
  }

  .img-skeleton {
    width: 260px !important;
    height: 170px !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr !important;
  }

  .info-desc-container {
    width: 340px !important;
  }

  .info-desc {
    -webkit-line-clamp: 5 !important;
    height: 150px !important;
  }

  .info-view-text {
    font-size: 18px !important;
  }

  .audio-icon-container {
    width: 28px !important;
    height: 28px !important;
  }

  .home-icon {
    margin-right: 0px !important;
  }

  .tutorial-icon {
    margin-right: 0px !important;
  }

  .full-screen-icon {
    margin-right: 0px !important;
  }

  .footer-icon {
    margin-right: 0px !important;
  }

  .language-btn {
    width: 85% !important;
  }

  .stepper-circle-multi {
    width: 18px !important;
    height: 18px !important;
  }

  .stepper-circle-multi-inner {
    width: 12px !important;
    height: 12px !important;
  }

  .hotspot-circle-container {
    top: 30px;
  }
}

/* @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .tutorial-dialog-container {
    width: 60%;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 12px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .info-dialog-img-sm {
    height: auto;
    max-height: 370px;
  }

  .full-dialog-image {
    max-width: 88vw;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 12px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tutorial-popup {
    top: 5%;
  }

  .tutorial-dialog-container {
    width: 40%;
  }

  .stepper-container {
    margin: 0 30px;
  }

  .stepper-location-name {
    font-size: 14px;
  }

  .stepper-location-name-active {
    font-size: 16px;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 14px;
    height: 14px;
  }

  .locations-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .info-dialog-img-sm {
    height: auto;
    max-height: 370px;
  }
} */

@media only screen and (min-device-width: 1200px) and (min-device-height: 2000px) and (max-device-width: 1300px) and (max-device-height: 2100px) and (orientation: portrait) {
  .mob-center-align {
    align-items: center;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-bg {
    height: auto;
    width: -webkit-fill-available;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .tutorial-dialog-container {
    width: 60%;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 362px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .info-dialog-img-sm {
    height: auto;
    /* max-height: 370px; */
  }

  .full-dialog-image {
    max-width: 88vw;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }
}

@media only screen and (min-device-width: 2000px) and (min-device-height: 1200px) and (max-device-width: 2100px) and (max-device-height: 1300px) and (orientation: landscape) {
  .mob-center-align {
    align-items: center;
  }

  .info-dialog {
    max-height: 640px;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 712px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .tutorial-popup {
    top: 5%;
  }

  .tutorial-dialog-container {
    width: 40%;
  }

  .stepper-container {
    margin: 0 30px;
  }

  .stepper-location-name {
    font-size: 14px;
  }

  .stepper-location-name-active {
    font-size: 16px;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 14px;
    height: 14px;
  }

  .locations-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .info-dialog-img-sm {
    height: auto;
    /* max-height: 370px; */
  }
}

@media only screen and (min-device-width: 768px) and (min-device-height: 1024px) and (max-device-width: 840px) and (max-device-height: 1199px) and (orientation: portrait) {
  .mob-center-align {
    align-items: center;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-bg {
    height: auto;
    width: -webkit-fill-available;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .tutorial-dialog-container {
    width: 60%;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 212px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .skeleton-container {
    width: 100% !important;
    height: 370px !important;
  }

  .img-skeleton {
    width: 94% !important;
    height: 360px !important;
  }

  .info-dialog {
    width: 100%;
  }

  .info-dialog-img-sm {
    width: 100%;
    height: auto;
    /* max-height: 370px; */
  }

  .full-dialog-image {
    max-width: 88vw;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }
}

@media only screen and (min-device-width: 1024px) and (min-device-height: 768px) and (max-device-width: 1199px) and (max-device-height: 840px) and (orientation: landscape) {
  .mob-center-align {
    align-items: center;
  }

  .info-dialog {
    max-height: 640px;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 312px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tutorial-popup {
    top: 5%;
  }

  .tutorial-dialog-container {
    width: 40%;
  }

  .stepper-container {
    margin: 0 30px;
  }

  .stepper-location-name {
    font-size: 14px;
  }

  .stepper-location-name-active {
    font-size: 16px;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 14px;
    height: 14px;
  }

  .locations-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .info-dialog-img-sm {
    height: auto;
    /* max-height: 370px; */
  }
}

@media only screen and (min-device-width: 1024px) and (min-device-height: 1366px) and (max-device-width: 1100px) and (max-device-height: 1400px) and (orientation: portrait) {
  .mob-center-align {
    align-items: center;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-bg {
    height: auto;
    width: -webkit-fill-available;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .tutorial-dialog-container {
    width: 60%;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 300px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .skeleton-container {
    width: 100% !important;
    height: 370px !important;
  }

  .img-skeleton {
    width: 94% !important;
    height: 360px !important;
  }

  .info-dialog {
    width: 100%;
  }

  .info-dialog-img-sm {
    width: 100%;
    height: auto;
    /* max-height: 370px; */
  }

  .full-dialog-image {
    max-width: 88vw;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }
}

@media only screen and (min-device-width: 1366px) and (min-device-height: 1024px) and (max-device-width: 1400px) and (max-device-height: 1100px) and (orientation: landscape) {
  .mob-center-align {
    align-items: center;
  }

  .info-dialog {
    max-height: 640px;
  }

  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 412px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tutorial-popup {
    top: 5%;
  }

  .tutorial-dialog-container {
    width: 40%;
  }

  .stepper-container {
    margin: 0 30px;
  }

  .stepper-location-name {
    font-size: 14px;
  }

  .stepper-location-name-active {
    font-size: 16px;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 14px;
    height: 14px;
  }

  .locations-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .info-dialog-img-sm {
    height: auto;
    /* max-height: 370px; */
  }
}

/* @media only screen and (min-device-width: 1024px) and  (min-device-height: 1366px) and (orientation: portrait) {
  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-bg {
    height: auto;
    width: -webkit-fill-available;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .tutorial-dialog-container {
    width: 60%;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 12px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .info-desc-container {
    height: auto;
    width: auto;
  }

  .info-dialog {
    z-index: 4;
    top: 70px;
    margin: 0 20px;
    width: 404px;
  }

  .info-dialog-img-sm {
    height: auto;
    max-height: 370px;
  }

  .full-dialog-image {
    max-width: 88vw;
  }

  .home-icon {
    margin-right: 0px;
  }

  .tutorial-icon {
    margin-right: 0px;
  }

  .full-screen-icon {
    margin-right: 0px;
  }

  .footer-icon {
    margin-right: 0px;
  }
}


@media only screen and (min-device-width: 1366px) and  (min-device-height: 1024px) and (orientation: landscape) {
  .landing-container {
    background: url("./images/landing-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .video-container {
    height: 100vh !important;
    display: none;
  }

  .footer-container {
    display: none;
  }

  .footer-container-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    width: 100vw;
    padding: 12px 12px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear;
  }

  .locations-btn-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: var(--primary-color);
    cursor: pointer;
    display: block;
    margin-bottom: 12px;
    margin-top: 8px;
  }

  .stepper-location-name-active {
    font-size: 16px;
    margin-top: 8px;
  }

  .mob-menu-btn {
    display: block !important;
    position: absolute !important;
    top: 6px;
    right: 10px;
    z-index: 2;
    color: white !important;
  }

  .location-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .tutorial-popup {
    top: 5%;
  }

  .tutorial-dialog-container {
    width: 40%;
  }

  .stepper-container {
    margin: 0 30px;
  }

  .stepper-location-name {
    font-size: 14px;
  }

  .stepper-location-name-active {
    font-size: 16px;
  }

  .stepper-circle-multi {
    width: 18px;
    height: 18px;
  }

  .stepper-circle-multi-inner {
    width: 14px;
    height: 14px;
  }

  .locations-btn {
    padding: 14px 20px !important;
    font-size: 14px !important;
  }

  .info-dialog-img-sm {
    height: auto;
    max-height: 370px;
  }
} */

@media (min-width: 1367px) and (max-width: 1500px) {
  .info-dialog-img-sm {
    height: auto;
    /* max-height: 400px; */
  }

  .tutorial-popup {
    top: 2%;
  }

  .full-dialog-image {
    max-height: 80vh;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d9d9d9;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #d9d9d9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}

.pannellum-image {
  width: 100vw;
  height: 100vh;
  position: absolute;
  /* z-index: 1; */
  background: rgba(52, 52, 52, 255);
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s linear, opacity 0.5s linear;
}

.pannellum-image-fade-out {
  /* -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both; */
  visibility: hidden;
  opacity: 0;
}

.pannellum-image-fade-in {
  /* -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  visibility: visible;
  opacity: 1; */
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  z-index: 1;
}

.pnlm-about-msg {
  visibility: hidden;
  a {
    visibility: hidden;
  }
}

.hide-prev-next-icon {
  visibility: hidden;
}
